import { FC } from "react";

import { Theme } from "apps/website/maps/Theme.map";

import Section from "../../layout/Section/Section";
import Container from "../../layout/Container/Container";
import StepByStep, {
  IStepByStepProps,
} from "../../feature/StepByStep/StepByStep";
import { getSectionSize } from "../../layout/Section/Section.utils";

export interface ISectionStepByStepProps {
  theme?: Theme;
  stepByStep: IStepByStepProps;
}

const SectionStepByStep: FC<ISectionStepByStepProps> = ({ theme = "default", stepByStep }) => (
  <Section data-component={ SectionStepByStep.name } theme={ theme } size={ getSectionSize(theme) }>
    <Container>
      <StepByStep { ...stepByStep } itemsGap="xl"/>
    </Container>
  </Section>
);

export default SectionStepByStep;
