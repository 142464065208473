import { IStoryBlokComponent } from "apps/website/components/page/api.types";

import TasteAndNutritionCard from "../../feature/Card/TasteAndNutritionCard/TasteAndNutritionCard";
import { AllTasteBrands } from "../../feature/Card/TasteAndNutritionCard/types";
import {
  CatFoodType,
} from "../../feature/Card/ValueForMoneyCard/ValueForMoneyCard";

export interface IStoryBlokTasteAndNutritionCard {
  brand: AllTasteBrands
  catFoodType: CatFoodType
}

export const StoryBlokTasteAndNutritionCard = ({ blok }: IStoryBlokComponent<IStoryBlokTasteAndNutritionCard>) => (
  <TasteAndNutritionCard brand={blok.brand} catFoodType={blok.catFoodType} />
);
