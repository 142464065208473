import React from "react";

import Image from "apps/website/components/base/Image/Image";
import TextSubtitle from "apps/website/components/base/Text/TextSubtitle/TextSubtitle";
import TextBody from "apps/website/components/base/Text/TextBody/TextBody";
import TrustPilotStars from "apps/website/components/misc/TrustPilotStars";

import { CatFoodType } from "../ValueForMoneyCard/ValueForMoneyCard";

import { brandMessages, HIDDEN_TASTE_WET_BRANDS } from "./shared/constants";
import { AllTasteBrands } from "./types";

export interface TasteAndNutritionCardProps {
  brand: AllTasteBrands
  catFoodType: CatFoodType
}

const TasteAndNutritionCard = ({ brand, catFoodType }: TasteAndNutritionCardProps) => {
  const message = brandMessages[brand] || null;

  if (catFoodType === "Wet" && HIDDEN_TASTE_WET_BRANDS.has(brand)) {
    return null;
  }

  if (!message) {
    return null;
  }

  return (
    <div className="flex flex-col items-start py-3 pr-3 pl-2 bg-white ">
      <div className="space-y-2 mb-2">
        <TextSubtitle size={2}>Developed by vets. Devoured by cats.</TextSubtitle>
        <TextBody size={4} className="!mb-0">
          { message }
        </TextBody>
      </div>

      <div className="bg-brand flex items-start py-3 px-2 w-full">
        <div className="flex-shrink-0 mr-2">
          <Image
            image={{
              src: "/images/dev/components/TasteAndNutritionCard/human_affection_with_cat.webp",
              height: 44,
              width: 44,
            }}
            alt="Human showing affection to a cat"
          />
        </div>
        <div className="space-y-2 flex-grow">
          <TextSubtitle size={4}>{ "\"Tilly cleans her bowl in one sitting.\"" }</TextSubtitle>
          <div className="h-[15px] w-[77px]">
            <TrustPilotStars rating={5} />
          </div>
          <TextBody size={3} weight={"bold"}>{ "Andre + Tilly" }</TextBody>
        </div>
      </div>
    </div>
  );
};

export default TasteAndNutritionCard;
