import { AllTasteBrands, SpecialTasteBrands, TasteBrands } from "../types";

const DEFAULT_TASTE_MESSAGE =
  "Our tasty, vet-formulated recipes are made from 100% real meat. More than any other brand on the market.";

const SPECIAL_TASTE_MESSAGE =
  "Our tasty, vet-formulated recipes are made from 100% real meat. Gently cooked to kill bad bacteria.";

const TasteBrandsArray = [
  "APPLAWS",
  "ALMO_NATURE",
  "AVA",
  "BLINK",
  "BURNS",
  "CARNILOVE",
  "CRAVE",
  "EDGARD_COOPER",
  "ENCORE",
  "FERINGA",
  "FRESHPET",
  "HARRINGTONS",
  "JAMES_WELLBELOVED",
  "LILYS_KITCHEN",
  "MEOWING_HEADS",
  "NATURES_MENU",
  "ORIJEN",
  "PURR&MIAOW",
  "REPUBLIC_OF_CATS",
  "THRIVE",
  "TOMOJO",
  "UNTAMED",
  "OTHER",
];

export const SpecialTasteBrandsArray: SpecialTasteBrands[] = [
  "PURRFORM",
  "NUTRIMENT",
  "NATURAL_INSTINCT",
];

export const HIDDEN_TASTE_WET_BRANDS: Set<AllTasteBrands> = new Set([
  "APPLAWS",
  "BLINK",
  "LILYS_KITCHEN",
  "THRIVE",
] as TasteBrands[]);

const brandMessages: Partial<Record<TasteBrands | SpecialTasteBrands, string>> = {};

SpecialTasteBrandsArray.forEach((brand) => {
  brandMessages[brand as SpecialTasteBrands] = SPECIAL_TASTE_MESSAGE;
});

TasteBrandsArray.forEach((brand) => {
  brandMessages[brand as TasteBrands] = DEFAULT_TASTE_MESSAGE;
});

export { brandMessages };
