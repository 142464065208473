import { FC, ReactNode } from "react";

import Image, { IImageProps } from "../../base/Image/Image";
import Grid from "../../layout/Grid/Grid";
import Column from "../../layout/Column/Column";
import TextTitle from "../../base/Text/TextTitle/TextTitle";
import TextSubtitle from "../../base/Text/TextSubtitle/TextSubtitle";
import TextBody from "../../base/Text/TextBody/TextBody";
import Spacer from "../../layout/Spacer/Spacer";
import * as ColumnMap from "../../layout/Column/Column.map";
import * as GridMap from "../../layout/Grid/Grid.map";

interface IStep {
  image: IImageProps;
  title: string;
  body: string | ReactNode;
  spans?: ColumnMap.Span | ColumnMap.BreakpointSpan
}

export type TStepOptionImageSize = "default" | "220px" | "full-width";

interface IStepOptions {
  imageSize?: TStepOptionImageSize;
}

export interface IStepByStepProps {
  hero?: IImageProps;
  title: string;
  steps: IStep[];
  options?: IStepOptions;
  itemsGap?: GridMap.Gap;
}

const STEP_BY_STEP_IMAGE_SIZE_CLASS_MAP: Record<TStepOptionImageSize, string> = {
  default: "",
  "220px": "max-w-[220px]",
  "full-width": "w-full",
};

const StepByStep: FC<IStepByStepProps> = (props) => (
  <Grid component={ StepByStep.name } >
    <>
      { props.hero?.src && (
        <Column className="pb-6 lg:pb-16">
          <Image image={ props.hero } alt={ props.title } data-testid="StepByStepImage" />
        </Column>
      ) }
      <Column>
        <TextTitle size={ 1 } align="center" data-testid="StepByStepTitle">{ props.title }</TextTitle>
        <Spacer size="lg" />
      </Column>
      <Column>
        <Grid tag="ol" gap={ props.itemsGap }>
          { props.steps.map((step, index) => (
            <Column key={ step.title } tag="li" spans={ step.spans || { lg: 4 } } align="center">
              <div className={ STEP_BY_STEP_IMAGE_SIZE_CLASS_MAP[props.options?.imageSize || "default"] }>
                <Image image={ step.image } alt={ step.title } data-testid="StepByStepItemImage" />
              </div>
              <Spacer size="md" />
              <div className="flex items-center justify-center">
                <span className="inline-flex items-center justify-center flex-none w-6 h-6 mr-2 text-xs text-white bg-black rounded-full font-display">{ index + 1 }</span>
                <TextSubtitle tag="h3" size={ 2 } align="center" data-testid="StepByStepItemTitle">{ step.title }</TextSubtitle>
              </div>
              <Spacer size="md" />
              <TextBody tag="div" size={ 2 } align="center" data-testid="StepByStepItemBody" whitespace="balance">{ step.body }</TextBody>
            </Column>
          )) }
        </Grid>
      </Column>
    </>
  </Grid>
);

export default StepByStep;
