import React from "react";

import Icon from "apps/website/components/base/Icon/Icon";
import TextSubtitle from "apps/website/components/base/Text/TextSubtitle/TextSubtitle";
import TextBody from "apps/website/components/base/Text/TextBody/TextBody";
import highlightTextWithHTML from "apps/website/utils/website/highlightTextWithHTML";

import { ValueForMoneyBrands } from "./types";
import {
  CAT_MAINLY_EATS,
  HIDDEN_VALUE_WET_BRANDS,
  valueForMoneyCardData,
} from "./constants";

export type CatFoodType = (typeof CAT_MAINLY_EATS)[number];
export interface ValueForMoneyCardProps {
  brand: ValueForMoneyBrands;
  catFoodType: CatFoodType;
  numberOfCats: string;
}

const ValueForMoneyCard = ({ brand, catFoodType, numberOfCats }: ValueForMoneyCardProps) => {

  // Removed as the save for money card will be shown instead of the value for money card
  if (catFoodType === "Wet" && HIDDEN_VALUE_WET_BRANDS.has(brand) && numberOfCats === "1") {
    return null;
  }

  // These account for 1% of traffic so we can hide the card here too.
  if (catFoodType === "Fresh" || catFoodType === "Raw") {
    return null;
  }

  const brandData = valueForMoneyCardData.find((item) => item.brand === brand);

  if (!brandData) {
    return null;
  }

  const { meatMultiplier, competitorProductName } = brandData.data;

  const meatMultiplierText = highlightTextWithHTML({
    text: `\`${meatMultiplier}x more meat.\``,
  });

  const meatComparisonText = `With KatKin, you'll get ${meatMultiplierText} than ${competitorProductName}. For the exact same price.`;

  return (
    <div className="flex items-start py-3 pr-3 pl-2 bg-white ">
      <div className="flex-shrink-0 mr-2">
        <Icon icon="coins" size="small" />
      </div>
      <div className="flex-grow space-y-2 ">
        <TextSubtitle size={2} className="text-wrap">Better Value for Money with KatKin</TextSubtitle>
        <TextBody size={3} className="!mb-0">
          <span dangerouslySetInnerHTML={{ __html: meatComparisonText }}/>
        </TextBody>
        <TextBody size={4} >
          *Price + ingredient comparison as of 31/01/2025, based on nutritional
          requirements for a 4kg cat.
        </TextBody>
      </div>
    </div>
  );
};

export default ValueForMoneyCard;
