import { DividerColor } from "./types";

export const dividerColorMap: Record<DividerColor, string> = {
  transparent: "bg-transparent",
  current: "bg-current",
  white: "bg-white",
  black: "bg-black",
  brand: "bg-brand",
  "light-grey": "bg-light-grey",
  "mid-grey": "bg-mid-grey",
  "dark-grey": "bg-dark-grey",
  charcoal: "bg-charcoal",
  orange: "bg-orange",
  green: "bg-green",
  "green-dark": "bg-green-dark",
  blue: "bg-blue",
  pink: "bg-pink",
  red: "bg-red",
  inherit: "bg-inherit",
  "light-blue": "bg-light-blue",
  "outline-grey": "bg-outline-grey",
  "graphite-grey": "bg-graphite-grey",
  "green-light": "bg-green-light",
};
