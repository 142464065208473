import { IStoryBlokComponent } from "apps/website/components/page/api.types";
import {
  ValueForMoneyBrands,
} from "apps/website/components/feature/Card/ValueForMoneyCard/types";
import {
  CatFoodType,
} from "apps/website/components/feature/Card/ValueForMoneyCard/ValueForMoneyCard";
import { useFormFieldValue } from "apps/website/hooks/useFormFieldValue";

import {
  IStoryBlokValueForMoneyCard,
  StoryBlokValueForMoneyCard,
} from "../StoryBlokValueForMoneyCard";

export const StoryBlokFormValueForMoneyCard = (component: IStoryBlokComponent<IStoryBlokValueForMoneyCard>) => {

  const { getFormFieldValue } = useFormFieldValue();
  const linkingId = component.linkingId || "0";

  const formComponent: IStoryBlokComponent<IStoryBlokValueForMoneyCard> = {
    blok: {
      ...component.blok,
      brand: getFormFieldValue<ValueForMoneyBrands>(component.flowId ?? "UNKNOWN", "catFoodBrand", linkingId, "" as ValueForMoneyBrands),
      catFoodType: getFormFieldValue<CatFoodType>(component.flowId ?? "UNKNOWN", "catFoodType", linkingId, "" as CatFoodType),
      numberOfCats: getFormFieldValue<string>(component.flowId ?? "UNKNOWN", "numberOfCats", "0", ""),
    },
  };

  return (
    <>
      <StoryBlokValueForMoneyCard {...formComponent} />
    </>
  );
};
