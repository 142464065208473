"use client";

import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import { ReadonlyURLSearchParams, useSearchParams } from "next/navigation";
import { usePostHog } from "posthog-js/react";

import {
  FieldMap,
  FieldsetComponent,
  FormComponent,
  SectionComponent,
  Story,
} from "@forms/schema";
import {
  FlowDataStructureResponse,
} from "apps/forms-structure/src/app/dto/FormDataStructureResponse.dto";
import {
  useNewFormsServiceStore,
  validateSection,
  convertFieldMapToSubmissionFieldValues,
  showFieldset,
  getRepeatedFieldsArray,
  getCurrentFlow,
} from "libs/state/src/lib/stores/useFormServiceStore";
import {
  isFieldset,
} from "apps/forms-structure/src/app/utils/forms-schema-type-guards";
import { useAuth, useDatadog } from "@auth/client-sdk-react";

import { useReturnToPath } from "../hooks/useReturnToPath";
import { useAPI } from "../hooks/useAPI";

import { useTrackPageView } from "./tracking";

interface SectionComponentWithMetadata extends SectionComponent {
  /** Index of the section, in regards to the entire flow */
  index: number;
  /** Whether this section belongs to the first form within the flow or not */
  isFirstForm: boolean;
  /** Whether this section is the first section of the first form within the flow */
  isFirstSection: boolean;
  /** Slug of the form that this section belongs to */
  form: string;
}

export interface IFlowContext {
  /** The current flow */
  flow: FlowDataStructureResponse;
  /** The current form */
  currentForm: Story<FormComponent>;
  /** The current section */
  currentSection: SectionComponent;
  /** The current index */
  currentSectionIndex: number;
  /** List of all the section names/slugs */
  sections: SectionComponentWithMetadata[];
  /** Go back */
  back(): void;
  /** Go forwards */
  next(forceNext?: boolean): void;
  /** Whether we can continue to the next section */
  canContinue: boolean;
  /** flow to reference set by query parameter */
  queryFlow?: string;
  /** Is the last section in a form */
  isLastSectionInForm(): boolean;
  /** Did they come through google shopping? */
  /** Are we just previewing the flow? */
  preview: boolean;
  /** Did they come through Hello Fresh? */
  /** Do they have any initial values to set? NOTE: Can only be used for non-linked values */
  formStoreQueryParamValues: {
    fieldName: string;
    fieldValue: string | number | boolean | string[];
  }[];
  /** The type of trial they have come through, e.g. google-shopping or hello-fresh */
  trialType?: string;
  /** Saves surveys if in survey */
  saveSurvey(lastSection: boolean): void;
}

export const flowContext = createContext<IFlowContext>(
  undefined as unknown as IFlowContext,
);

export interface IFlowProviderProps {
  flow: FlowDataStructureResponse;
  initialForm: string;
  initialSection: string;
  preview: boolean;
  numberOfSpacers?: number;
}

export const useFlow = () => useContext(flowContext);
interface FlowSearchParams extends ReadonlyURLSearchParams {
  relatedFlowSlug?: string;
  "google-shopping"?: string;
  "hello-fresh"?: string;
  "trial-type"?: string;
}

export const FlowProvider: FC<PropsWithChildren<IFlowProviderProps>> = ({
  flow,
  initialForm,
  initialSection,
  children,
  preview,
  numberOfSpacers,
}) => {
  const query: FlowSearchParams = useSearchParams();
  const posthog = usePostHog();
  const datadog = useDatadog();
  const searchParams = useSearchParams();
  const { accessToken } = useAuth();
  const { clearFlowErrors } = useNewFormsServiceStore();

  const { trackPageView } = useTrackPageView();
  const { SurveyAnswers: surveyAnswers } = useAPI();

  const returnToPath = useReturnToPath();

  const root = useMemo(() => `/forms/${flow.slug}`, [ flow ]);

  const [ experimentMap, setExperimentMap ] = useState<Record<string, string> | null>(null);
  const overrideAppliedRef = useRef(false);

  const queryFlow =
    typeof query?.relatedFlowSlug === "string"
      ? query.relatedFlowSlug
      : undefined;

  const [ currentForm, setCurrentForm ] = useState<Story<FormComponent>>(
    flow.mappedFlow.forms[0],
  );
  const [ trialType, setTrialType ] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (query.has("google-shopping")) {
      setTrialType("GOOGLE_SHOPPING");
    }
    if (query.has("hello-fresh")) {
      setTrialType("HELLO_FRESH");
    }
    if (query.has("trial-type")) {
      setTrialType(query.get("trial-type")?.toUpperCase() ?? undefined);
    }
  }, [ searchParams ]);

  useEffect(() => {
    const formToSet = flow.mappedFlow.forms.find(
      (mappedForm: Story<FormComponent>) => mappedForm.slug === initialForm,
    );

    if (formToSet) {
      setCurrentForm(formToSet);
    }
  }, [ flow.mappedFlow.forms, initialForm ]);

  useEffect(() => {
    if (searchParams.get("test") === "true") return;

    const experimentsRunning: string[] = [
      "hide-non-default-recipes",
      "age-personalisation",
      "scoop-planet-quantity-x-frequency-2",
      "discount-during-cancellation",
      "retry-remove-why-fresh-from-suf-2",
      "price-to-meat",
    ]; // To be manually filled in when running experiments, e.g. [ "james-test-flag" ]

    // Apply overrides and set up listener
    if (flow.slug === "fresh-food-new-user" && !overrideAppliedRef.current) {
      const overrides: Record<string, string> = {};
      experimentsRunning.forEach((ex) => {
        if (ex !== "discount-during-cancellation") {
          overrides[ex] = "control";
        }
      });
      posthog.featureFlags.overrideFeatureFlags({ flags: overrides });
      overrideAppliedRef.current = true;
    }

    // Set up feature flag listener to maintain local state
    posthog.onFeatureFlags(() => {
      if (experimentMap) return;

      const exMap: Record<string, string> = {};
      experimentsRunning.forEach((ex) => {
        // For Meta SUF, we want to set all experiments to control
        if (flow.slug === "fresh-new-user-meta" && ex !== "discount-during-cancellation") {
          exMap[ex] = "control";
        } else {
          // For the normal flow, get the actual feature flag value
          exMap[ex] = posthog.getFeatureFlag(ex) as string;
        }
      });
      setExperimentMap(exMap);
    });
  }, [ experimentMap, posthog, flow.slug, searchParams ]);

  const allSections: SectionComponentWithMetadata[] = useMemo(
    () => flow.mappedFlow.forms
      .flatMap((form: Story<FormComponent>, formIndex: number) => form.content.sections.map(
        (section: SectionComponent, sectionIndex) => ({
          ...section,
          isFirstForm: formIndex === 0,
          isFirstSection: formIndex === 0 && sectionIndex === 0,
          form: form.slug,
        }),
      ))
      .map((s, index) => ({ index, ...s })),
    [ flow ],
  );

  // First get the sections that are in an experiment.
  // i.e. there are multiple sections with the same slug
  const experimentSections = useMemo(
    () => allSections.filter((sec) => {
      if (sec.experiment_variant && sec.experiment_flag) {
        return (
          experimentMap?.[sec.experiment_flag] &&
            experimentMap[sec.experiment_flag] === sec.experiment_variant
        );
      }
      return false;
    }),
    [ allSections, experimentMap ],
  );

  const sections = useMemo(
    () => allSections
      .filter((s) => {
        // Sections to show: either: the section is in the experiment sections AND has the right values;
        // OR the experiment flag and experiment variant aren't set.
        const sectionIsInExperiment = experimentSections.some(
          (es) => es.slug === s.slug,
        );
        if (sectionIsInExperiment) {
          return experimentSections.some(
            (es) => es.slug === s.slug &&
                es.experiment_flag === s.experiment_flag &&
                es.experiment_variant === s.experiment_variant,
          );
        }
        return !(s.experiment_flag && s.experiment_variant);
      })
      .map((section, sectionIndex) => ({ ...section, index: sectionIndex })),
    [ allSections, experimentSections ],
  );

  useEffect(() => {
    if (!experimentMap) {
      return;
    }
    const path = window.location.pathname.split("/");
    const doesSectionExistInPath = path.length > 4;
    const actualSection = doesSectionExistInPath
      ? path[4].split("?")[0]
      : initialSection;
    let sectionToSet = sections.find(
      (section) => section.slug === actualSection,
    );
    if (
      !sectionToSet &&
      flow.mappedFlow.forms[0].slug === currentForm?.slug &&
      !initialSection &&
      !doesSectionExistInPath
    ) {
      const correctForm = flow.mappedFlow.forms
        .map((form, formIndex) => ({
          formSlug: form.slug,
          sections: form.content.sections,
          formIndex,
        }))
        .find((f) => f.formSlug === currentForm?.slug);

      const filteredSections = sections.filter((s) => correctForm?.sections.some((sec) => sec.slug === s.slug));

      [ sectionToSet ] = filteredSections;
    }
    if (sectionToSet) {
      setCurrentSection(sectionToSet);
    }
  }, [ experimentMap, flow, accessToken ]);

  const [ currentSection, setCurrentSection ] = useState(sections[0]);

  const currentSectionIndex = useMemo(
    () => sections.map((s) => s.slug).indexOf(currentSection.slug),
    [ sections, currentSection ],
  );

  const currentSectionData = useNewFormsServiceStore(({ flows }) => {
    const flowData = flows.find((f) => f.id === flow.slug);

    const fieldNamesAndLabelsInCurrentSection = currentSection?.fieldsets
      .filter((fieldset) => !!isFieldset(fieldset))
      ?.flatMap((fieldSet) => (fieldSet as FieldsetComponent).fields.map((field) => field.name));

    const sectionData = flowData?.fields?.filter(
      (field) => fieldNamesAndLabelsInCurrentSection.includes(field.key.fieldName),
    );

    return sectionData;
  });

  const currentSectionDataWithLabels = useCallback(() => {
    const flowData = getCurrentFlow(flow.slug);

    const fieldNamesAndLabelsInCurrentSection = currentSection?.fieldsets
      .filter((fieldset) => !!isFieldset(fieldset))
      ?.flatMap((fieldSet) => (fieldSet as FieldsetComponent).fields.map((field) => ({
        name: field.name,
        label:
            field.question ||
            field.label ||
            (fieldSet as FieldsetComponent).title,
      }))); // Not sure if this is the best way to get the wording of the question, but makes sense for now.

    const sectionData = flowData?.fields
      ?.filter((field: FieldMap) => fieldNamesAndLabelsInCurrentSection.some(
        (x) => x.name === field.key.fieldName,
      ))
      .map((f: FieldMap) => {
        const field = fieldNamesAndLabelsInCurrentSection.find(
          (x) => x.name === f.key.fieldName,
        );
        return { ...f, label: field?.label };
      });
    return sectionData;
  }, [ currentSection?.fieldsets, flow.slug ]);

  const getLastSectionDataWithLabels = useNewFormsServiceStore(({ flows }) => {
    const lastSection = allSections[allSections.length - 1];
    const flowData = flows.find((f) => f.id === flow.slug);

    const fieldNamesAndLabelsInCurrentSection = lastSection?.fieldsets
      .filter((fieldset) => !!isFieldset(fieldset))
      ?.flatMap((fieldSet) => (fieldSet as FieldsetComponent).fields.map((field) => ({
        name: field.name,
        label:
            field.question ||
            field.label ||
            (fieldSet as FieldsetComponent).title,
      }))); // Not sure if this is the best way to get the wording of the question, but makes sense for now.

    const sectionData = flowData?.fields
      ?.filter((field) => fieldNamesAndLabelsInCurrentSection.some(
        (x) => x.name === field.key.fieldName,
      ))
      .map((f) => {
        const field = fieldNamesAndLabelsInCurrentSection.find(
          (x) => x.name === f.key.fieldName,
        );
        return { ...f, label: field?.label };
      });

    return sectionData;
  });

  const formStoreQueryParamValues = useMemo(() => {
    const fieldsInFlow = flow.mappedFlow.forms
      .flatMap((f) => f.content.sections)
      .flatMap((s) => s.fieldsets)
      .filter((fs) => isFieldset(fs))
      .flatMap((fs) => (fs as FieldsetComponent).fields)
      .flatMap((field) => field.name);

    const fieldValuesFromQueryParams: {
      fieldName: string;
      fieldValue: string | number | boolean | string[];
    }[] = fieldsInFlow
      .filter(
        (x) => typeof query.get(x) === "string" ||
          typeof query.get(x) === "number" ||
          typeof query.get(x) === "boolean",
      )
      .map((fieldName) => {
        const result = { fieldName, fieldValue: query.get(fieldName) || "" };
        return result;
      });
    if (typeof query.get("discount_code") === "string") {
      fieldValuesFromQueryParams.push({
        fieldName: "discountCode",
        fieldValue: query.get("discount_code") as string,
      });
    }
    return fieldValuesFromQueryParams;
  }, [ flow.mappedFlow.forms, query ]);

  const canContinue = useMemo(
    () => preview || validateSection(flow.slug, currentSection, datadog?.logger),
    // NB: currentSectionData is here as a hack to re-validate the current section whenever it changes,
    // as validateSection function doesn't subscribe to it automatically
    [ flow, currentSection, currentSectionData ],
  );

  const setUrl = useCallback(
    (
      prevForm: Story<FormComponent>,
      prevSection: SectionComponentWithMetadata,
      nextForm: Story<FormComponent>,
      nextSection: SectionComponentWithMetadata,
    ) => {
      const params = new URLSearchParams(window.location.search);
      const newUrl = `/forms/${flow.slug}/${nextForm.slug}/${
        nextSection.slug
      }?${params.toString()}`;

      console.log(nextForm.slug);
      console.log(nextSection.slug);

      console.log("SET FORM SLUG", newUrl);

      // router.push(newUrl);

      window.history.pushState(
        {
          prevForm: prevForm.slug,
          prevSection: prevSection.slug,
        }, // Unused arg
        "", // Unused arg
        newUrl,
      );
      setCurrentForm(nextForm);
      setCurrentSection(nextSection);

      trackPageView();
    },
    [ flow, trackPageView, currentForm, currentSection ],
  );

  const saveSurvey = useCallback(
    async (flowExit = false) => {
      if (flow.mappedFlow.survey) {
        if (flowExit) {
          // Bit of a hack
          const sectionData = getLastSectionDataWithLabels;
          const forSubmission =
            convertFieldMapToSubmissionFieldValues(sectionData);
          await surveyAnswers.storeSurveyAnswers(forSubmission, flow.slug);
        } else {
          const forSubmission = convertFieldMapToSubmissionFieldValues(
            currentSectionDataWithLabels(),
          );
          await surveyAnswers.storeSurveyAnswers(forSubmission, flow.slug);
        }
      }
    },
    [
      flow.mappedFlow.survey,
      flow.slug,
      getLastSectionDataWithLabels,
      surveyAnswers,
      currentSectionDataWithLabels,
    ],
  );

  const next = useCallback(
    async (forceContinue = undefined) => {
      if (!canContinue && !forceContinue) return;
      const prevForm = currentForm;
      const prevSection = currentSection;

      // Next section is determined by the conditionality of the fieldsets within that section
      let nextSectionIndex = currentSectionIndex + 1;
      // eslint-disable-next-line no-plusplus
      for (let i = nextSectionIndex; i < sections.length; i++) {
        if (sections[i].fieldsets.filter(isFieldset).length === 0) {
          nextSectionIndex = i;
          break;
        }
        // Basically this code is ensuring that we actually want to show the next section based
        // on if there are any fieldsets that are conditional
        if (
          sections[i].fieldsets
            .filter(isFieldset)
            .some((fs) => getRepeatedFieldsArray(flow.slug, fs.repeat_from).some(
              (linkingId) => showFieldset(fs, linkingId, flow.slug),
            ))
        ) {
          nextSectionIndex = i;
          break;
        }
      }

      const nextSection = sections[nextSectionIndex];

      const nextForm = flow.mappedFlow.forms.find(
        (form) => form.slug === nextSection.form,
      );

      await saveSurvey();

      if (nextForm && currentForm) {
        setUrl(prevForm, prevSection, nextForm, nextSection);
      }
    },
    [
      currentForm,
      currentSection,
      setUrl,
      canContinue,
      sections,
      currentSectionIndex,
      flow.mappedFlow,
    ],
  );

  useEffect(() => {}, [ currentSection ]);

  const back = useCallback(() => {
    // If we are on the last page already, then navigate to `returnToPath`
    if (currentSectionIndex === 0 && returnToPath) {
      window.location.href = returnToPath; // Cannot use router here as it may be part of another application
    } else {
      clearFlowErrors(flow.slug);

      let prevSectionIndex = currentSectionIndex - 1;
      // eslint-disable-next-line no-plusplus
      for (let i = prevSectionIndex; i > -1; i--) {
        console.log("Checking previous section", i, sections[i].slug);
        // Basically this code is ensuring that we actually want to show the previous section based
        // on if there are any fieldsets that are conditional
        if (
          sections[i].fieldsets
            .filter(isFieldset)
            .some((fs) => getRepeatedFieldsArray(flow.slug, fs.repeat_from).some(
              (linkingId) => showFieldset(fs, linkingId, flow.slug),
            ))
        ) {
          console.log("Previous section valid!");
          prevSectionIndex = i;
          break;
        }
      }

      const nextSection = sections[prevSectionIndex];

      setCurrentSection(nextSection);

      console.log(currentSection);

      const nextForm = flow.mappedFlow.forms.find(
        (form) => form.slug === nextSection.form,
      );
      if (nextForm && currentForm) {
        setCurrentForm(nextForm);
        setUrl(currentForm, currentSection, nextForm, nextSection);
      }
    }
  }, [
    currentForm,
    currentSection,
    setUrl,
    sections,
    returnToPath,
    currentSectionIndex,
    flow.mappedFlow.forms,
  ]);

  const isLastSectionInForm = useCallback(
    () => currentForm?.content.sections[currentForm.content.sections.length - 1]
      ._uid === currentSection._uid,
    [ currentForm?.content.sections, currentSection._uid ],
  );

  useEffect(() => {
    function onPopState(event: PopStateEvent) {
      const { state } = event;

      const prevFormSlug: string = state.prevForm;
      const prevSectionSlug: string = state.prevSection;

      const sectionToGoTo = sections.find((s) => s.slug === prevSectionSlug);
      const formToGoTo = flow.mappedFlow.forms.find(
        (form) => form.slug === prevFormSlug,
      );

      if (sectionToGoTo && formToGoTo) {
        setCurrentSection(sectionToGoTo);
        setCurrentForm(formToGoTo);
        trackPageView();
      } else {
        window.location.reload();
      }
    }
    window.addEventListener("popstate", onPopState);
    return () => window.removeEventListener("popstate", onPopState);
  }, [ setCurrentSection, setCurrentForm, trackPageView, flow, sections ]);

  const value: IFlowContext = useMemo(
    () => ({
      flow,
      root,
      currentForm,
      currentSection,
      sections,
      back,
      next,
      currentSectionIndex,
      isLastSectionInForm,
      canContinue,
      queryFlow,
      preview,
      formStoreQueryParamValues,
      trialType,
      saveSurvey,
      numberOfSpacers,
    }),
    [
      flow,
      root,
      currentForm,
      currentSection,
      sections,
      back,
      next,
      currentSectionIndex,
      isLastSectionInForm,
      canContinue,
      queryFlow,
      preview,
      formStoreQueryParamValues,
      trialType,
      saveSurvey,
      numberOfSpacers,
    ],
  );

  return <flowContext.Provider value={value}>{ children }</flowContext.Provider>;
};
