import { IStoryBlokComponent } from "apps/website/components/page/api.types";

import ValueForMoneyCard, {
  CatFoodType,
} from "../../feature/Card/ValueForMoneyCard/ValueForMoneyCard";
import {
  ValueForMoneyBrands,
} from "../../feature/Card/ValueForMoneyCard/types";

export interface IStoryBlokValueForMoneyCard {
  brand: ValueForMoneyBrands
  catFoodType: CatFoodType
  numberOfCats: string
}

export const StoryBlokValueForMoneyCard = ({ blok }: IStoryBlokComponent<IStoryBlokValueForMoneyCard>) => (
  <ValueForMoneyCard
    brand={blok.brand}
    catFoodType={blok.catFoodType}
    numberOfCats={blok.numberOfCats}
  />
);
