import {
  ExtraProduct,
  ExtraFreshProduct,
} from "libs/api/customer/src/lib/types/products";

const PRODUCT_NAMES: string[] = [
  "CLUCK",
  "GOBBLE",
  "QUACK",
  "MOOO",
  "SPLASH",
  "OINK",
  "BAAA",
  "NIBBLES_CHICKEN",
  "NIBBLES_SALMON",
  "PLANET_SCOOP",
  "HEALTH_SCOOP",
];

type TProductName = typeof PRODUCT_NAMES[number];

export enum EProduct {
  CLUCK,
  GOBBLE,
  QUACK,
  MOOO,
  SPLASH,
  OINK,
  BAAA,
  NIBBLES_CHICKEN,
  NIBBLES_SALMON,
  PLANET_SCOOP,
  HEALTH_SCOOP,
}

export enum EProductCategory {
  FRESH,
  SCOOP,
  UNKNOWN,
  EXTRAS,
}

/**
 * Filename for each product should be consistent across all folders e.g. cluck.webp
 */
interface IProductFileNames {
  image: string;
}

interface IProductIngredients {
  core: string[];
}

interface IProduct {
  key: string;
  title: string;
  ingredients: IProductIngredients;
  productCategory: EProductCategory;
  fileNames: IProductFileNames;
}

export type TProductMap = Record<EProduct, IProduct>;

export const PRODUCT_NAME_TO_ENUM_MAP: Record<TProductName, EProduct> = {
  CLUCK: EProduct.CLUCK,
  GOBBLE: EProduct.GOBBLE,
  QUACK: EProduct.QUACK,
  MOOO: EProduct.MOOO,
  SPLASH: EProduct.SPLASH,
  OINK: EProduct.OINK,
  BAAA: EProduct.BAAA,
  NIBBLES_CHICKEN: EProduct.NIBBLES_CHICKEN,
  NIBBLES_SALMON: EProduct.NIBBLES_SALMON,
  PLANET_SCOOP: EProduct.PLANET_SCOOP,
  HEALTH_SCOOP: EProduct.HEALTH_SCOOP,
};

export const PRODUCT_MAP: TProductMap = {
  [EProduct.CLUCK]: {
    key: "CLUCK",
    title: "Cluck",
    ingredients: {
      core: [ "chicken" ],
    },
    productCategory: EProductCategory.FRESH,
    fileNames: {
      image: "cluck.webp",
    },
  },
  [EProduct.GOBBLE]: {
    key: "GOBBLE",
    title: "Gobble",
    ingredients: {
      core: [ "turkey" ],
    },
    productCategory: EProductCategory.FRESH,
    fileNames: {
      image: "gobble.webp",
    },
  },
  [EProduct.QUACK]: {
    key: "QUACK",
    title: "Quack",
    ingredients: {
      core: [ "duck" ],
    },
    productCategory: EProductCategory.FRESH,
    fileNames: {
      image: "quack.webp",
    },
  },
  [EProduct.MOOO]: {
    key: "MOOO",
    title: "Mooo",
    ingredients: {
      core: [ "beef" ],
    },
    productCategory: EProductCategory.FRESH,
    fileNames: {
      image: "mooo.webp",
    },
  },
  [EProduct.SPLASH]: {
    key: "SPLASH",
    title: "Splash",
    ingredients: {
      core: [ "fish", "turkey" ],
    },
    productCategory: EProductCategory.FRESH,
    fileNames: {
      image: "splash.webp",
    },
  },
  [EProduct.OINK]: {
    key: "OINK",
    title: "Oink",
    ingredients: {
      core: [ "pork" ],
    },
    productCategory: EProductCategory.FRESH,
    fileNames: {
      image: "oink.webp",
    },
  },
  [EProduct.BAAA]: {
    key: "BAAA",
    title: "Baaa",
    ingredients: {
      core: [ "lamb" ],
    },
    productCategory: EProductCategory.FRESH,
    fileNames: {
      image: "baaa.webp",
    },
  },
  [EProduct.NIBBLES_CHICKEN]: {
    key: "NIBBLES_CHICKEN",
    title: "Chicken nibbles",
    ingredients: {
      core: [ ],
    },
    productCategory: EProductCategory.EXTRAS,
    fileNames: {
      image: "nibbles-chicken.webp",
    },
  },
  [EProduct.NIBBLES_SALMON]: {
    key: "NIBBLES_SALMON",
    title: "Salmon nibbles",
    ingredients: {
      core: [ ],
    },
    productCategory: EProductCategory.EXTRAS,
    fileNames: {
      image: "nibbles-salmon.webp",
    },
  },
  [EProduct.HEALTH_SCOOP]: {
    key: "HEALTH_SCOOP",
    title: "Health",
    ingredients: {
      core: [ ],
    },
    productCategory: EProductCategory.SCOOP,
    fileNames: {
      image: "scoop-health.webp",
    },
  },
  [EProduct.PLANET_SCOOP]: {
    key: "PLANET_SCOOP",
    title: "Planet",
    ingredients: {
      core: [ ],
    },
    productCategory: EProductCategory.SCOOP,
    fileNames: {
      image: "scoop-planet.webp",
    },
  },
};

export const EXTRA_PRODUCT: Partial<Record<ExtraProduct, string>> = {
  HEALTH_SCOOP_LITTER: "Scoop Health",
  PLANET_SCOOP_LITTER: "Scoop Planet",
};

export const EXTRA_FRESH_PRODUCT: Partial<Record<ExtraFreshProduct, string>> = {
  NIBBLES_CHICKEN: "Chicken Nibbles",
  NIBBLES_SALMON: "Salmon Nibbles",
  SPRINKLES: "Sprinkles",
};

export const VALID_SCOOP_DELIVERY_INTERVALS: number[] = Array.from({ length: 16 }, (_, value) => (value + 1));

export function isValidProductName(name: string): name is TProductName {
  return PRODUCT_NAMES.includes(name);
}

export const getProductFromName = (name: TProductName): IProduct => {
  const productEnum = PRODUCT_NAME_TO_ENUM_MAP[name];

  return PRODUCT_MAP[productEnum];
};

const IMAGE_KEY_TO_FOLDER_MAP = {
  MEAT_CONTENT: "meat-content",
  PACKAGING: "packaging",
} as const;

type TImageKey = keyof typeof IMAGE_KEY_TO_FOLDER_MAP;

interface IProductImage {
  src: string;
  width: number;
  height: number;
}

export const getProductImage = (fileName: string, key: TImageKey): IProductImage => ({
  src: `/images/features/product-overview/${IMAGE_KEY_TO_FOLDER_MAP[key]}/${fileName}`,
  width: 800,
  height: 800,
});
