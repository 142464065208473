import { IStoryBlokComponent } from "apps/website/components/page/api.types";
import {
  AllTasteBrands,
} from "apps/website/components/feature/Card/TasteAndNutritionCard/types";
import {
  CatFoodType,
} from "apps/website/components/feature/Card/ValueForMoneyCard/ValueForMoneyCard";
import { useFormFieldValue } from "apps/website/hooks/useFormFieldValue";

import {
  IStoryBlokTasteAndNutritionCard,
  StoryBlokTasteAndNutritionCard,
} from "../StoryBlokTasteAndNutritionCard";

export const StoryBlokFormTasteAndNutritionCard = (component: IStoryBlokComponent<IStoryBlokTasteAndNutritionCard>) => {
  const { getFormFieldValue } = useFormFieldValue();
  const linkingId = component.linkingId || "0";

  const formComponent: IStoryBlokComponent<IStoryBlokTasteAndNutritionCard> = {
    blok: {
      ...component.blok,
      brand: getFormFieldValue<AllTasteBrands>(component.flowId ?? "UNKNOWN", "catFoodBrand", linkingId, "" as AllTasteBrands),
      catFoodType: getFormFieldValue<CatFoodType>(component.flowId ?? "UNKNOWN", "catFoodType", linkingId, "" as CatFoodType),
    },
  };

  return (
    <>
      <StoryBlokTasteAndNutritionCard {...formComponent} />
    </>
  );
};
