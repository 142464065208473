import { useNewFormsServiceStore } from "@./state";

/**
 * Custom hook to safely retrieve a field value from the form.
 * @returns A function to fetch form field values.
 */
export const useFormFieldValue = () => {
  const { getActiveFieldValue } = useNewFormsServiceStore();

  /**
 * @param flowId The form flow id.
 * @param field The field name.
 * @param linkingId The linking id.
 * @param fallback The fallback value.
 * @returns The field value.
 */
  const getFormFieldValue = <T extends string>(
    flowId: string,
    field: string,
    linkingId: string,
    fallback: T = "" as T,
  ): T => {

    const value = getActiveFieldValue(flowId, field, linkingId)?.data.submitValue as T | undefined;

    return value ?? fallback;
  };

  return { getFormFieldValue };
};
