import { FC } from "react";

import { PRODUCT_MAP, EProduct, getProductImage } from "@/constants/products";

import Tabs from "../Tabs";
import { ITab } from "../shared/types";

import ProductOverviewTab from "./ProductOverviewTab/ProductOverviewTab";
import { PRODUCT_OVERVIEW_TABS_BULLET_POINTS } from "./ProductOverviewTabs.map";

const TABS: ITab[] = Object.entries(PRODUCT_OVERVIEW_TABS_BULLET_POINTS).map(([ key, value ]) => (
  {
    ...PRODUCT_MAP[key as unknown as EProduct],
    Content: <ProductOverviewTab
      tabType={"productOverview"}
      title={ PRODUCT_MAP[key as unknown as EProduct].title }
      image={{ ...getProductImage(PRODUCT_MAP[key as unknown as EProduct].fileNames.image, "MEAT_CONTENT") } }
      coreIngredients={ PRODUCT_MAP[key as unknown as EProduct].ingredients.core }
      bulletPoints={ value } />,
  }
));

const ProductOverviewTabs: FC = () => (
  <div data-component={ ProductOverviewTabs.name }>
    <Tabs tabs={ TABS } />
  </div>
);

export default ProductOverviewTabs;
