import { ValueForMoneyBrands } from "./types";

export interface IValueForMoneyCardItem {
  meatMultiplier?: string;
  competitorProductName?: string;
}

interface IValueForMoneyCardData {
  brand: ValueForMoneyBrands;
  data: IValueForMoneyCardItem;
}

export const valueForMoneyCardData: IValueForMoneyCardData[] = [
  {
    brand: "FELIX",
    data: {
      meatMultiplier: "12.32",
      competitorProductName: "Felix Chicken in Jelly",
    },
  },
  {
    brand: "WHISKAS",
    data: {
      meatMultiplier: "22.15",
      competitorProductName: "Whiskas 1+ Poultry Feasts",
    },
  },
  {
    brand: "GOURMET",
    data: {
      meatMultiplier: "21.91",
      competitorProductName: "Gourmet Gold Wet Adult Cat Food",
    },
  },
  {
    brand: "SHEBA",
    data: {
      meatMultiplier: "29.54",
      competitorProductName: "Sheba Natures Collection Wet Adult Cat Food",
    },
  },
  {
    brand: "PURINA_ONE",
    data: {
      meatMultiplier: "9.15",
      competitorProductName: "Purina ONE Adult Mini Fillets Wet Cat Food Gravy",
    },
  },
  {
    brand: "HILLS",
    data: {
      meatMultiplier: "6.97",
      competitorProductName: "Adult Wet Cat Food Chicken",
    },
  },
  {
    brand: "IAMS",
    data: {
      meatMultiplier: "5.75",
      competitorProductName: "IAMS Delights Wet Adult Cat Food",
    },
  },
  {
    brand: "SCRUMBLES",
    data: {
      meatMultiplier: "6.62",
      competitorProductName: "Chicken Shredded Cat Food in Broth",
    },
  },
  {
    brand: "SUPERMARKET_BRAND",
    data: {
      meatMultiplier: "7.84",
      competitorProductName: "Tesco Adult Cat Poultry Selection In Jelly",
    },
  },
  {
    brand: "GO_CAT",
    data: {
      meatMultiplier: "2.65",
      competitorProductName: "GoCat Tuna and Herring Dry Food",
    },
  },
];

export const CAT_MAINLY_EATS = [ "Wet", "Dry", "Home-cooked", "Raw", "Fresh" ] as const;

export const HIDDEN_VALUE_WET_BRANDS: Set<ValueForMoneyBrands> = new Set([
  "APPLAWS",
  "SHEBA",
  "ROYAL_CANIN",
  "PURINA_ONE",
  "SCRUMBLES",
] as ValueForMoneyBrands[]);
